/* global attachEvent */

// *******************************************************************************
// Close Modal
// *******************************************************************************

attachEvent('.modal__close', 'click', (ev) => {
  const parentModal = ev.target.closest('.modal');
  parentModal.classList.remove('is-open-modal');
});

// *******************************************************************************
// Close Modal When Click Out
// *******************************************************************************

document.addEventListener('click', (evt) => {
  const modalContainer = document.querySelector('.modal__body');
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement === modalContainer) {
      return;
    }
    targetElement = targetElement.parentNode;
  } while (targetElement);

  document.querySelectorAll('.modal').forEach((el) => {
    el.classList.remove('is-open-modal');
  });
});

// *******************************************************************************
// Close Modal When Escape Key Press
// *******************************************************************************

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    document.querySelectorAll('.modal').forEach((el) => {
      el.classList.remove('is-open-modal');
    });
  }
});
