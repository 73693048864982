// *******************************************************************************
// Input Fields
// *******************************************************************************

const textFieldElement = document.querySelectorAll('.input-field__input');

Array.prototype.forEach.call(textFieldElement, (el) => {
  // eslint-disable-next-line no-unused-vars
  el.addEventListener('focus', (ev) => {
    const parentElement = el.closest('.input-field');
    parentElement.classList.add('is-focused');
  });

  // eslint-disable-next-line no-unused-vars
  el.addEventListener('blur', (ev) => {
    const parentElement = el.closest('.input-field');
    if (!el.value) {
      parentElement.classList.remove('is-focused');
    }
  });
});
