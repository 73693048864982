function $(elid) {
  return document.getElementById(elid);
}

let cursor;

function init() {
  cursor = $('cursor');
  cursor.style.left = '0px';
}

function nl2br(txt) {
  return txt.replace(/\n/g, '<br />');
}

// eslint-disable-next-line no-unused-vars
function writeit(from, e) {
  const key = e.keyCode || e.charCode;
  const tw = from.value;
  const twl = from.value.length;
  if ((key === 8 || key === 46) && twl < 7 && twl > 0) {
    const cursorHTML = cursor.innerHTML;
    const newHTML = `${cursorHTML}__`;
    cursor.innerHTML = newHTML;
  } else {
    // eslint-disable-next-line no-param-reassign
    e = e || window.event;
    const w = $('writer');
    w.innerHTML = nl2br(tw);
  }
}

if ($('setter')) {
  $('setter').addEventListener('keydown', function (event) {
    writeit(this, event);
  });

  $('setter').addEventListener('input', function (e) {
    writeit(this, e);

    const charLength = this.value.length;
    // const parentElem = e.target.closest('.game__step');
    // const lockButtonParent = parentElem.querySelector('.game__button');
    // const lockButton = parentElem.querySelector('.btn');

    if (charLength > 5) {
      cursor.style.display = 'none';
      // lockButtonParent.classList.remove('button-locked');
      // lockButton.setAttribute('aria-disabled', false);
    } else {
      cursor.style.display = 'inline';
      // lockButtonParent.classList.add('button-locked');
      // lockButton.setAttribute('aria-disabled', true);
    }
    const cursorHTML = cursor.innerHTML;
    const newHTML = cursorHTML.substring(0, cursorHTML.length - 1);
    cursor.innerHTML = newHTML;
  });

  $('terminal').addEventListener('onclick', () => {
    $('setter').focus();
  });

  window.onload = function () {
    init();
    $('setter').focus();
  };
}
