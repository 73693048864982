/* global Cookies, attachEvent */

const cookieElement = document.querySelector('.cookie-banner');

if (cookieElement) {
  if (!Cookies.get('cookie-banner-seen')) {
    cookieElement.classList.add('cookie-not-set');
  }

  attachEvent('.btn-cookie', 'click', (ev) => {
    Cookies.set('cookie-banner-seen', 'cookie-set', { expires: 7 });
    cookieElement.classList.remove('cookie-not-set');
  });
}
